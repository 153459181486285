import React from "react"

import Hakusi from '../../layouts/hakusi'

import firebase from 'firebase/app'
import 'firebase/firestore'


// このページを表示した時に、１回だけ「購入した」と広告に送信するための準備

// LocalStorageの「mitaka」に「一回でもこのページを見た」ことを記録
if (typeof document !== `undefined`) {

	// LcalStorageのmitakaがない時
	if (!localStorage.mitaka) {
		// localStorage.mitakaを作成
		localStorage.mitaka = true
		// alert('sakusei-go:'+ localStorage.mitaka) //debug
	}
	// 後は、タグマネージャーがこのLcalStorageを見て判断してくれる
}


//入力されたライセンスキーを、データベースと照合して、LocalStorageに保存する
class LicenseForm extends React.Component {


	constructor(props) {
		super(props);

		// フォームの値
		this.state = {
			value: ''
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	// 入力されたら、フォームの値を更新する
	handleChange(event) {
		this.setState({
			value: event.target.value
		});
	}

	//送信ボタンをを押したら、
	handleSubmit(event) {

		//Init Firestore
		if (!firebase.apps.length) {
			let config = {
				apiKey: "AIzaSyBNCGNyBwbfMAaFyqn-IWMp-Zsum5Tnsqw",
				authDomain: "elvn-project.firebaseapp.com",
				databaseURL: "https://elvn-project.firebaseio.com",
				projectId: "elvn-project",
				storageBucket: "elvn-project.appspot.com",
				messagingSenderId: "1087876269075"
			};
			// Init Firebase
			firebase.initializeApp(config);
		}


		// フォームの値を、変数keyに保存
		let key = this.state.value;
		// console.log('var key = ' +key); // debug

		// フォームが空だと、アラートを出す
		if (!key) {
			alert('キーを入力してください')
		} else {
			//データベースと照合する
			const db = firebase.firestore();
			let docRef = db.collection("license").doc(key);
			docRef.get().then(function (doc) {

				//合致するデータがあった時
				if (doc.exists) { //※支払いのチェックを追加する
					// console.log("attayo! :", doc.data()); // debug

					// LocalStorageの「lk」にキーを保存
					if (typeof document !== `undefined`) {
						localStorage.lk = key
						// alert(localStorage.lk) //debug

						// LocalStorageに個人情報を保存する
						let kojin = doc.data()
						localStorage.email = kojin["Email"]
						localStorage.seimei = kojin["SEI"] + kojin["MEI"]
						// console.log(kojin) // debug
						// console.log(kojin["Email"]) // debug
						// console.log(kojin["SEI"]) // debug
						// console.log(kojin["MEI"]) // debug
						// alert('redirexting') // debug
					}

					//スタートページにリダイレクトする
					if (typeof document !== `undefined`) {
						window.location.replace("/elements/start/");
					}
				}

				//合致するデータがなかった時
				else {
					// LocalStorageの「lk」を削除する
					if (typeof document !== `undefined`) {
						localStorage.removeItem('lk')
					}

					// アラートを出す
					alert('正しいキーを入力してください' + '（両端に不要なスペース等が入っていませんか？代金の未払い等はありませんか？）' + 'どちらにも該当しない場合は、まず他のブラウザや、他の端末で試してください。' + 'それでもダメなら、ログインサーバーがダウンしている可能性があります。手動で対応しますので、ページ下部のメールアドレスへお名前とメールアドレスを添えて「ログインできません」と送ってください。')
				}

			}).catch(function (error) {
				alert("エラー：", error)
			})
			event.preventDefault()
		}
	}






	render() {


		return (

			<Hakusi>


				<div className="pad butinuki" />

				<section class="kanaami butinuki t-center">


					<h3 class='moji_kage_koi'> ライセンスキーを入力してください </h3>
					<form onSubmit={ this.handleSubmit }>
						<label for='login_form' style={ { display: `none` } }> ライセンスキー
						</label> <input id="login_form" type="text" value={ this.state.value } onChange={ this.handleChange }
							placeholder='XXXXXXXX-XXXXXXXX-XXXXXXXX-XXXXXXXX' />

						<input className='login_btn' type="submit" value="ログイン" />
					</form>
					<p className='t-small t-bold' style={ { margin: `5em` } }>
						一度キーを入力すれば、 以降はこのページを経由せずに、 直接教材を見ることができます </p>
					<p className="t-small" style={ { textAlign: `left` } }> ※正常にログインできない場合は、
						<br />ページ下部のメールアドレスよりお問い合わせください。
						<br />
						対応いたします。
					</p>
				</section>
				<div className="pad butinuki" />


			</Hakusi>

		);
	}
}

export default LicenseForm